import React, { useCallback, useState, useEffect, useRef } from 'react'
import { motion } from "framer-motion"
import styles from './Callback.module.scss'
import emailjs from 'emailjs-com'
import { FormValidation } from '../../hooks/FormValidation'
import InputMask from "react-input-mask"
import wp from '../../image/Callback/wp.svg'
import tg from '../../image/Callback/tg.svg'

const boxAnimation = {
    hidden: {
        x: -500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .6 }
    }
}

const formAnimation = {
    hidden: {
        x: 500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .6 }
    }
}


export const Callback = ({popupOpen}) => {

    const handleForm = FormValidation()
    const [buttonDisabled, setButtonDisabled] = useState(true)
    const form = useRef()

    useEffect(() => {
        handleForm.isValid ? setButtonDisabled(false) : setButtonDisabled(true)
    }, [handleForm.isValid])

    const sendData = useCallback((evt) => {
        evt.preventDefault();
            emailjs.sendForm('service_a916yrv', 'template_wjxqmpe', form.current, 'uzyrI04U3I5LhTzkh')
            .then((result) => {
            }, (error) => {
                console.log(error.text);
            }); 
        handleForm.resetForm()
        popupOpen()
    }, [handleForm, popupOpen] )


    return (
        <motion.div 
            className={styles.container_form}
                initial='hidden'
                whileInView='visible'
                viewport={{amount: 0.2, once: true}}
                >
                <motion.div className={styles.box} variants={boxAnimation}>
                    <h2 className={styles.title}>Остались<span> вопросы?</span></h2>
                    <p className={styles.text}>Если у Вас остались вопросы по производству древесной шерсти, ценам или схеме работы, напишите нам в Telegram, WhatsApp, или заполните форму обратной связи</p>
                    <div className={styles.list}>
                            <a className={styles.link} target='_blank' rel='noopener noreferrer' href='https://wa.me/+79671961984' aria-label='связаться вацап'>
                                <img src={wp} alt="Whatsapp"/>
                                <span>Whatsapp</span>
                            </a>
                            <a className={styles.link_telegram} target='_blank' rel='noopener noreferrer' href='https://t.me/+79671961984' aria-label='телеграмм'>
                                <img src={tg} alt="Whatsapp"/>
                                <span>telegram</span>
                            </a>
                    </div>
                </motion.div>
                <motion.form className={styles.form} onSubmit={sendData} ref={form}  variants={formAnimation}>
                    <div className={styles.box_input}>
                        <label className={styles.label}>Введите имя
                            <input
                                className={`${styles.input} ${handleForm.errors.name && styles.wrong}`}
                                type='text' 
                                name='name'
                                value={handleForm.values.this}
                                onChange={handleForm.handleChange} 
                                pattern='^[A-Za-zА-Яа-яЁё\s\-]{2,30}$'
                                required/>
                        </label>
                    </div>
                    <span className={styles.input_error}>{handleForm.errors.name}</span>
                    <div className={styles.box_input}>
                        <label className={styles.label}>Введите телефон      
                            <InputMask
                                className={`${styles.input} ${handleForm.errors.phone && styles.wrong}`}
                                type='tel' 
                                name='phone'
                                value={handleForm.values.this}
                                onChange={handleForm.handleChange}
                                mask={'+7\\(999) 999-99-99'}  
                                pattern='\+7\s?[\(][0-9]{3}[\)]{0,1}\s?\d{3}[-]{0,1}\d{2}[-]{0,1}\d{2}'
                                required/>
                        </label>
                    </div>
                    <span className={styles.input_error}>{handleForm.errors.phone}</span> 
                    <div className={styles.box_textarea}>
                        <label className={styles.label_textarea}>Комментарий
                            <textarea
                                    className={`${styles.textarea} ${styles.scrollbar}`}
                                    name='comment'
                                    value={handleForm.values.this}
                                    onChange={handleForm.handleChange}/>       
                        </label>
                    </div>                
                            <button className={`${styles.button_form} ${buttonDisabled && styles.button_disabled}`}type='submit'><span>Отправить</span></button>
                </motion.form>
            </motion.div> 
    )
}