import React from 'react'
import { motion } from "framer-motion"
import styles from './AboutUs.module.scss'
import image from '../../image/AboutUs/img.webp'

const imageAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },

    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: .8 }
    }
}

const textAnimation = {
    hidden: {
        x: 500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .6 }
    }
}

export const AboutUs = () => {

    return (
        <motion.section 
            className={styles.main} 
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            id='AboutUs'             
            >
            <div className={styles.container}>
                <motion.img className={styles.image} src={image} alt='металл' variants={imageAnimation}/>
                <motion.div className={styles.box_info} variants={textAnimation}>
                        <h2 className={styles.title}>О нашей <span>компании</span></h2>
                        <p className={styles.text}>&#8195;Наша компания является одним из ведущих производителей древесной шерсти на территори Московской области.</p>
                        <p className={styles.text}>&#8195;Основным продуктом нашего производства является древесная шерсть из лиственных и хвойных пород деревьев. Наша продукция - это полностью натуральный, экологически чистый, гипоаллергенный и безопасный материал, который широко используется во многих отрослях и сферах деятельности.</p>
                        <p className={styles.text}>&#8195;Для производства древесной шерсти мы применяем современное высокотехнологичное оборудование и высокосортное сырье (ровные, бездефектные стволы деревьев), благодаря чему мы гарантируем высокое качество продукции.</p>
                        <p className={styles.text}>&#8195;Выбирая нас, Вы получаете надежного партнера, - производителя натуральной продукции для устойчивого развития Вашего бизнеса.</p>
                    </motion.div>
            </div>
        </motion.section>
    )
}