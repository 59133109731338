import React from 'react'
import styles from './Instalment.module.scss'
import card from '../../image/Instalment/card.webp'

const Instalment = () => {

    return (
        <section className={styles.main}>
            <div className={styles.box}>
                <div className={styles.box_info}>
                    <h2 className={styles.title}>Оплата заказа <span>онлайн</span></h2>
                    <p className={styles.text}>Дорогие друзья, мы рады Вам сообщить, что на нашем сайте вы можете оплатить заказ онлайн как полностью, так и с помощью удобной беспроцентной рассрочки, предоставляемой Альфа-Банк</p>
                    <a className={styles.button} href="https://payment.alfabank.ru/sc/JZvMZPowOnmHADHo" target='_blank' rel='noopener noreferrer'>Оплатить заказ</a>
                </div>
                <img className={styles.card} src={card} alt='карточка'/>
            </div>
        </section>
        
    )
}

export default Instalment