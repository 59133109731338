import React from 'react'
import styles from './Advantages.module.scss'
import { motion } from "framer-motion"

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const boxAnimation = {
    hidden: {
        y: 400,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .4}
    } 
}


export const Advantages = ({isOpen}) => {

    return (
        <motion.section 
        className={styles.main} 
        id='Advantages'
        initial='hidden'
        whileInView='visible'
        viewport={{amount: 0.2, once: true}}
        >
        <div className={styles.container}>
        <motion.h2 className={styles.title} variants={titleAnimation}>Почему <span> выбирают нас?</span></motion.h2>
        <motion.ul className={styles.list} variants={boxAnimation}>
                    <li className={styles.item}>
                        <p className={styles.number}>01</p>
                        <h3 className={styles.item_title}>Доступные цены</h3>
                        <p className={styles.item_text}>Cобственное производство и отсутствие цепочки посредников позволяют нам держать цены на максимально доступном для наших клиентов уровне.</p>
                    </li>
                    <li className={styles.item}>
                        <p className={styles.number}>02</p>
                        <h3 className={styles.item_title}>Высокое качество</h3>
                        <p className={styles.item_text}>Мы осуществляем контроль качества на каждом из этапов. Вся продукци изготавливается из высокосортной и просушенной древесины без мусора и трухи соответствует ГОСТ 5244-79.</p>
                    </li>
                    <li className={styles.item}>
                        <p className={styles.number}>03</p>
                        <h3 className={styles.item_title}>Соблюдение сроков</h3>
                        <p className={styles.item_text}>Никогда не срываем сроки указанные в договоре. Ценим время наших клиентов и достигнутые договоренности. Вы всегда получите шерсть вовремя.</p>
                    </li>
                    <li className={styles.item}>
                        <p className={styles.number}>04</p>
                        <h3 className={styles.item_title}>Работа под заказ</h3>
                        <p className={styles.item_text}>В зависимости от сферы деятельности и индивидуальных потребностей заказчика мы регулируем процентное содержание лиственных и хвойных пород.</p>
                    </li>
                    <li className={styles.item}>
                        <p className={styles.number}>05</p>
                        <h3 className={styles.item_title}>Индивидуальный подход</h3>
                        <p className={styles.item_text}>Мы одинаково хорошо относимся ко всем категориям клиентов, для каждого мы готовы предложить индивидуальные условия сотрудничества.</p>
                    </li>
                    <li className={styles.item}>
                        <p className={styles.number}>06</p>
                        <h3 className={styles.item_title}>Огромный опыт</h3>
                        <p className={styles.item_text}>Мы не компания однодневка, завтра и через год наш контактный номер не будет «не в зоне доступа» или выключен. Работаем с 2015 и готовы для вас быть надежным долгосрочным партнером.</p>
                    </li>
                </motion.ul>
        </div>   
    </motion.section>
    )
}