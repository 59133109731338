import React from 'react'
import styles from './Certificates.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import {  Pagination, Autoplay} from "swiper"
import "./Certificates.css"
import 'swiper/scss'
import "swiper/scss/pagination"
import "swiper/scss/navigation"
import { arrCertificates } from '../../utils/data'
import { v4 as uuidv4 } from 'uuid'


export const Certificates = ({isImageOpen}) => {

    return (
        <section className={styles.main} id='Certificates'>
            <div className={styles.box}>
                <h2 className={styles.title}>Сертификаты нашей  <span>продукции</span></h2>
                    <div className={styles.slider}>
                        <Swiper
                                spaceBetween={10}
                                slidesPerView={3}
                                centeredSlides={true}
                                loop={true}
                                autoplay={{
                                    delay: 3000,
                                    disableOnInteraction: false,
                                }}
                                breakpoints={{

                                    1400: {
                                        slidesPerView: 3,
                                        spaceBetween: 10,
                                    },

                                    768: {
                                        slidesPerView: 3,
                                        spaceBetween: 5,
                                    },

                                    425: {
                                        slidesPerView: 1.5,
                                        spaceBetween: 5,
                                    },

                                    320: {
                                        slidesPerView: 1.3,
                                        spaceBetween: 5,
                                    },
                                }}
                                modules={[Pagination, Autoplay]}
                                className="mySwiper"
                                >
                            {arrCertificates.map((item, index) =>{
                                const keyUid = uuidv4()
                                if (index + 1 <= arrCertificates.length) {
                                    
                                    return(
                                        <SwiperSlide key={keyUid}>
                                            <img className={styles.image}  src={item.image} alt='сертификат' onClick={() => {isImageOpen(index)}}/>
                                        </SwiperSlide>
                                    ) 
                                } else {
                                    return ''
                                }
                            })}       
                        </Swiper>
                    </div>
                </div>
        </section>
    )
}